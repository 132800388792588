import { addPropertyControls, ControlType, RenderTarget, withCSS } from "framer"
import { useCallback, useEffect, useMemo, useState, useRef } from "react"
import { motion } from "framer-motion"
import { createGlobalStyle } from "styled-components"

import {
    useRadius,
    borderRadiusControl,
} from "https://framer.com/m/framer/default-utils.js@^0.45.0"

import YouTube from "react-youtube"

const GlobalStyles = createGlobalStyle`
    .video-responsive {
        overflow: hidden;
        padding-bottom: 56.25%;
        position: relative;
        height: 0;
        }

        .video-responsive iframe {
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        position: absolute;
        }

`

/**
 * YOUTUBE PLAYER
 * MADE BY LENDERSON MACEDO
 * @lenderson.macedo
 *
 * @framerIntrinsicWidth 375
 * @framerIntrinsicHeight 210
 * @framerDisableUnlink
 *
 * @framerSupportedLayoutWidth fixed
 * @framerSupportedLayoutHeight fixed
 */
export default function YoutubePlayer(props) {
    const {
        videoUrl,
        autoplay,
        playOnCanvas,
        mute,
        controls,
        loop,
        titles,
        eventTrigger: { startLesson, completeLesson },
        onLessonStart = () => {},
        onLessonComplete = () => {},
        onPlay = () => {},
        onPause = () => {},
        onEnd = () => {},
    } = props

    const [player, setPlayer] = useState(null)
    const [opts, setOpts] = useState({
        playerVars: {
            autoplay:
                RenderTarget.current() === RenderTarget.canvas
                    ? playOnCanvas && autoplay
                    : autoplay,
            mute: mute ? 1 : 0,
            loop: loop ? 1 : 0,
            modestbranding: 1,

            color: "white",
            controls: controls ? 1 : 0,
            showinfo: 0,
            rel: 0,
            iv_load_policy: 3,
        },
    })
    const startEventFired = useRef(false)
    const completeEventFired = useRef(false)

    const borderRadius = useRadius(props)

    const checkProgress = (player) => {
        const currentTime = player.getCurrentTime()
        const duration = player.getDuration()

        if (!startEventFired.current && currentTime >= startLesson) {
            onLessonStart()
            startEventFired.current = true
        }

        if (
            !completeEventFired.current &&
            duration - currentTime <= completeLesson
        ) {
            onLessonComplete()
            completeEventFired.current = true
        }
    }

    useEffect(() => {
        if (player) {
            const progressInterval = setInterval(() => {
                checkProgress(player)
            }, 1000)

            return () => {
                clearInterval(progressInterval)
            }
        }
    }, [player])

    const getAutoplayValue = useCallback(() => {
        if (RenderTarget.current() === RenderTarget.canvas) {
            return playOnCanvas && autoplay ? 1 : 0
        } else {
            return autoplay ? 1 : 0
        }
    }, [playOnCanvas, autoplay])

    useEffect(() => {
        setOpts({
            playerVars: {
                autoplay: getAutoplayValue(),
                mute: mute ? 1 : 0,
                loop: loop ? 1 : 0,
                color: "white",
                controls: controls ? 1 : 0,
                showinfo: 0,
                rel: 0,
                iv_load_policy: 3,
                modestbranding: 0,
            },
        })
    }, [autoplay, playOnCanvas, mute, loop, controls, player])

    const onPlayerReady = (event) => {
        setPlayer(event.target)
    }

    const onPlayerStateChange = (event) => {
        if (event.data === YouTube.PlayerState.PLAYING) {
            onPlay()
        } else if (event.data === YouTube.PlayerState.PAUSED) {
            onPause()
        } else if (event.data === YouTube.PlayerState.ENDED) {
            onEnd()
        }
    }

    if (!videoUrl) {
        return <> Video not Provided </>
    }

    const videoId = getYoutubeVideoId(videoUrl)

    useEffect(() => {}, [])
    return (
        <>
            <GlobalStyles />
            <YouTube
                videoId={videoId}
                opts={opts}
                style={{
                    borderRadius,
                }}
                className={"video-responsive"}
                onReady={onPlayerReady}
                onStateChange={onPlayerStateChange}
            />
        </>
    )
}

YoutubePlayer.defaultProps = {
    videoUrl: "https://www.youtube.com/watch?v=MnLTSqCYn2k&t=5625s",
    colors: {
        playerColor: true,
    },
    mute: false,
    loop: false,
    controls: true,
    autoplay: false,
}

const getYoutubeVideoId = (videoUrl: string) => {
    let url = new URL(videoUrl)
    if (
        url.hostname.endsWith("youtube.com") ||
        url.hostname.endsWith("youtube-nocookie.com")
    ) {
        const pathSegments = url.pathname.slice(1).split("/")
        if (pathSegments[0] === "watch") {
            return url.searchParams.get("v")
        }
        if (pathSegments[0] === "embed") {
            return pathSegments[1]
        }
    } else if (url.hostname === "youtu.be") {
        return url.pathname.slice(1)
    }
    return null
}

const videoStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
}

addPropertyControls(YoutubePlayer, {
    videoUrl: {
        title: "URL do vimeo",
        type: ControlType.String,
    },
    autoplay: {
        title: "Autoplay",
        type: ControlType.Boolean,
        enabledTitle: "Play",
        disabledTitle: "Pause",
    },
    playOnCanvas: {
        title: "On Canvas",
        type: ControlType.Boolean,
        enabledTitle: "Play",
        disabledTitle: "Pause",
    },
    loop: {
        title: "Loop",
        type: ControlType.Boolean,
        enabledTitle: "Yes",
        disabledTitle: "No",
    },
    mute: {
        title: "Mute",
        type: ControlType.Boolean,
        enabledTitle: "Yes",
        disabledTitle: "No",
    },
    controls: {
        title: "Controls",
        type: ControlType.Boolean,
        enabledTitle: "Show",
        disabledTitle: "Hide",
    },
    eventTrigger: {
        type: ControlType.Object,
        description: "Set Time to trigger events",
        controls: {
            startLesson: {
                title: "onStart",
                type: ControlType.Number,
                min: 0,
                max: 100,
                defaultValue: 15,
                displayStepper: true,
                unit: "seg",
            },
            completeLesson: {
                title: "onComplete",
                type: ControlType.Number,
                min: 0,
                max: 100,
                defaultValue: 15,
                displayStepper: true,
                unit: "seg",
            },
        },
    },
    ...borderRadiusControl,
    onLessonStart: {
        type: ControlType.EventHandler,
    },
    onLessonComplete: {
        type: ControlType.EventHandler,
    },
    onPlay: {
        type: ControlType.EventHandler,
    },
    onPause: {
        type: ControlType.EventHandler,
    },
    onEnd: {
        type: ControlType.EventHandler,
    },
})
